
import { BCard, BRow, BCol } from "bootstrap-vue";
import { Component, Vue } from "vue-property-decorator";
import { AvChartBar, AvChartPie, AvChartRange } from "@/components/av-charts";
import {
  AvSkeletonChartBar,
  AvSkeletonChartPie,
} from "@/components/av-skeleton";
import { AvButton } from "@/components";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// import jsPDF from "jspdf";
// import html2canvas from "html2canvas";
import * as am5 from "@amcharts/amcharts5";
import Ripple from "vue-ripple-directive";
import useExport from "./useExport";

// Interfaces
import {
  IGroup,
  IParticipation,
  IBrandVariation,
  IResponseGetLargestEconomicGroupsYear,
  IResponseGetTotalMarketShareCurrentMonth,
  IResponseGetTotalMarketShareYearToDate,
  IResponseGetGrowthByBrand,
  IResponseGetDirectSaleVsRetailSaleBrand,
  IResponseGetDirectSaleVsRetailSaleYear,
  IResponseGetBrandEvolution,
  IResponseGetDailyBrandEvolution,
  IEvolution,
} from "@core/services/interfaces/oldService/IOldService";
import {
  IBarConfig,
  IPieConfig,
  IPieData,
  IRangeConfig,
  IRangeData,
} from "@/components/av-charts/interfaces/IConfig";

@Component({
  name: "KpiChartBeta",
  components: {
    BCard,
    BRow,
    BCol,
    AvButton,
    AvChartBar,
    AvChartPie,
    AvChartRange,
    AvSkeletonChartBar,
    AvSkeletonChartPie,
  },
  directives: {
    Ripple,
  },
})
export default class KpiChartBeta extends Vue {
  totalMarketShareCurrentMonth: {
    data: IParticipation[];
    title: string;
    root: am5.Root;
    config: IBarConfig;
  } = {
    data: [],
    title: "",
    root: {} as am5.Root,
    config: {
      categoryField: "FaeNome",
      categoryXField: "FaeNome",
      valueYField: "Total",
      tooltipText: "{categoryX}: {valueY}",
      renderer: {
        minGridDistance: 0,
        labels: {
          paddingTop: -7,
          paddingLeft: 0,
          rotation: -90,
        },
      },
    },
  };
  totalMarketShareYearToDate: {
    data: IParticipation[];
    title: string;
    root: am5.Root;
    config: IBarConfig;
  } = {
    data: [],
    title: "",
    root: {} as am5.Root,
    config: {
      categoryField: "FaeNome",
      categoryXField: "FaeNome",
      valueYField: "Total",
      tooltipText: "{categoryX}: {valueY}",
      renderer: {
        minGridDistance: 0,
        labels: {
          paddingTop: -7,
          paddingLeft: 0,
          rotation: -90,
        },
      },
    },
  };
  largestEconomicGroupsYearDate: {
    data: IGroup[];
    title: string;
    root: am5.Root;
    config: IBarConfig;
  } = {
    data: [],
    title: "",
    root: {} as am5.Root,
    config: {
      categoryField: "GrupoEmpresarial",
      categoryXField: "GrupoEmpresarial",
      valueYField: "Vendas",
      renderer: {
        minGridDistance: 30,
        labels: {
          rotation: -45,
          centerX: am5.percent(125),
          paddingRight: 0,
          fontSize: 10,
        },
      },
    },
  };
  growthByBrand: {
    data: IBrandVariation[];
    title: string;
    root: am5.Root;
    config: IBarConfig;
  } = {
    data: [],
    title: "",
    root: {} as am5.Root,
    config: {
      categoryField: "Nome",
      categoryXField: "Nome",
      valueYField: "Total",
      renderer: {
        minGridDistance: 30,
        labels: {
          centerX: am5.percent(125),
          paddingTop: 10,
          rotation: -45,
        },
      },
    },
  };
  directSaleVsRetailSaleBrandDate: {
    data: IPieData[];
    title: string;
    root: am5.Root;
    config: IPieConfig;
  } = {
    data: [],
    title: "",
    root: {} as am5.Root,
    config: {},
  };
  directSaleVsRetailSaleYearDate: {
    data: IPieData[];
    title: string;
    root: am5.Root;
    config: IPieConfig;
  } = {
    data: [],
    title: "",
    root: {} as am5.Root,
    config: {},
  };
  brandEvolutionData: {
    data: IRangeData[];
    date: string;
    title: string;
    root: am5.Root;
    config: IRangeConfig;
  } = {
    data: [],
    date: "",
    title: "",
    root: {} as am5.Root,
    config: {
      timeUnit: "month",
      initialZoom: {
        xs: -3,
        sm: -4,
        md: -5,
        lg: -6,
      },
      series: [
        {
          name: "Series",
          valueYField: "Varejo",
          valueXField: "date",
          openValueYField: "Total",
          stroke: "#828282",
          fill: "#828282",
          template: {
            fillOpacity: 0.6,
            visible: true,
          },
          onBullet: true,
          bullet: {
            locationX: 0.5,
            locationY: 0.5,
            sprite: {
              radius: 5,
              strokeWidth: 2,
              tooltipText: "{valueY}",
              showTooltipOn: "always",
              tooltip: {
                labelText: "{valueY}",
              },
            },
          },
        },
        {
          name: "Series",
          valueYField: "Total",
          valueXField: "date",
          stroke: this.reportColor,
          fill: this.reportColor,
          onBullet: true,
          bullet: {
            locationX: 0.5,
            locationY: 0.5,
            sprite: {
              draw: function (display) {
                display.drawRect(0, 0, 5, 5);
              },
              tooltipText: "{valueY}",
              showTooltipOn: "always",
              tooltip: {},
            },
          },
        },
      ],
    },
  };
  dailyBrandEvolutionData: {
    data: IRangeData[];
    date: string;
    title: string;
    root: am5.Root;
    config: IRangeConfig;
  } = {
    data: [],
    date: "",
    title: "",
    root: {} as am5.Root,
    config: {
      timeUnit: "day",
      initialZoom: {
        xs: -3,
        sm: -4,
        md: -5,
        lg: -6,
      },
      series: [
        {
          name: "Series",
          valueYField: "Varejo",
          valueXField: "date",
          openValueYField: "Total",
          stroke: "#828282",
          fill: "#828282",
          template: {
            fillOpacity: 0.6,
            visible: true,
          },
        },
        {
          name: "Series",
          valueYField: "Total",
          valueXField: "date",
          stroke: this.reportColor,
          fill: this.reportColor,
        },
      ],
    },
  };

  useExport = useExport(this);

  loadingTotalMarketShareCurrentMonth = true;
  loadingTotalMarketShareYearToDate = true;
  loadingLargestEconomicGroupsYearDate = true;
  loadingGrowthByBrand = true;
  loadingDirectSaleVsRetailSaleBrand = true;
  loadingDirectSaleVsRetailSaleYear = true;
  loadingBrandEvolution = true;
  loadingDailyBrandEvolution = true;
  updateDashboard = false;

  // Computeds
  get getBrand(): string | undefined {
    return this.$store.state["appConfig"].params.EMP_FABRICANTE;
  }

  get reportColor(): string {
    return "#" + this.$store.state["appConfig"].params.EMP_RELATORIOS_COR;
  }

  get updatingData(): boolean {
    return (
      this.loadingTotalMarketShareCurrentMonth &&
      this.loadingTotalMarketShareYearToDate &&
      this.loadingLargestEconomicGroupsYearDate &&
      this.loadingGrowthByBrand &&
      this.loadingDirectSaleVsRetailSaleBrand &&
      this.loadingDirectSaleVsRetailSaleYear &&
      this.loadingBrandEvolution &&
      this.loadingDailyBrandEvolution
    );
  }

  get labelVDxVEMarket(): string {
    const brand = this.getBrand ? this.getBrand : "";
    return localStorage.getItem("domain") == "Abracaf" ? "Mercado" : brand;
  }

  get printUnavailable(): boolean {
    return (
      this.loadingTotalMarketShareCurrentMonth ||
      this.loadingTotalMarketShareYearToDate ||
      this.loadingLargestEconomicGroupsYearDate ||
      this.loadingGrowthByBrand ||
      this.loadingDirectSaleVsRetailSaleBrand ||
      this.loadingDirectSaleVsRetailSaleYear ||
      this.loadingBrandEvolution ||
      this.loadingDailyBrandEvolution
    );
  }

  get pie1Data(): {
    loading: boolean;
    data: IPieData[];
    title: string;
    root: am5.Root;
    config: IPieConfig;
  } {
    return localStorage.getItem("domain") == "Abracaf"
      ? {
          ...this.directSaleVsRetailSaleYearDate,
          loading: this.loadingDirectSaleVsRetailSaleYear,
        }
      : {
          ...this.directSaleVsRetailSaleBrandDate,
          loading: this.loadingDirectSaleVsRetailSaleBrand,
        };
  }

  get pie2Data(): {
    loading: boolean;
    data: IPieData[];
    title: string;
    root: am5.Root;
    config: IPieConfig;
  } {
    return localStorage.getItem("domain") == "Abracaf"
      ? {
          ...this.directSaleVsRetailSaleBrandDate,
          loading: this.loadingDirectSaleVsRetailSaleBrand,
        }
      : {
          ...this.directSaleVsRetailSaleYearDate,
          loading: this.loadingDirectSaleVsRetailSaleYear,
        };
  }

  created() {
    this.refreshData();
  }

  async refreshData() {
    await this.fetchGetTotalMarketShareCurrentMonth();
    this.fetchGetTotalMarketShareYearToDate();
    this.fetchGetLargestEconomicGroupsYearDate();
    this.fetchGetGrowthByBrand();
    this.fetchDirectSaleVsRetailSaleBrand();
    this.fetchDirectSaleVsRetailSaleYear();
    this.fetchGetBrandEvolution();
    this.fetchGetDailyBrandEvolution();
  }

  async fetchGetTotalMarketShareCurrentMonth() {
    this.loadingTotalMarketShareCurrentMonth = true;

    await this.$store
      .dispatch("old-service/fetchGetTotalMarketShareCurrentMonth")
      .then((response: IResponseGetTotalMarketShareCurrentMonth) => {
        this.totalMarketShareCurrentMonth.data =
          response.data.Participacoes.map((participant: IParticipation) => ({
            ...participant,
            columnSettings: {
              fill:
                participant.FaeNome == this.getBrand
                  ? this.reportColor
                  : "#828282",
            },
          }));
        this.totalMarketShareCurrentMonth.title = response.data.Titulo;
        this.loadingTotalMarketShareCurrentMonth = false;
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Não foi possível consultar participação de mercado total!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  }

  fetchGetTotalMarketShareYearToDate() {
    this.loadingTotalMarketShareYearToDate = true;

    this.$store
      .dispatch("old-service/fetchGetTotalMarketShareYearToDate")
      .then((response: IResponseGetTotalMarketShareYearToDate) => {
        this.totalMarketShareYearToDate.data = response.data.Participacoes.map(
          (participant: IParticipation) => ({
            ...participant,
            columnSettings: {
              fill:
                participant.FaeNome == this.getBrand
                  ? this.reportColor
                  : "#828282",
            },
          })
        );
        this.totalMarketShareYearToDate.title = response.data.Titulo;
        this.loadingTotalMarketShareYearToDate = false;
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title:
              "Não foi possível consultar participação de mercado total, acumulado do ano!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  }

  fetchGetLargestEconomicGroupsYearDate() {
    this.loadingLargestEconomicGroupsYearDate = true;

    this.$store
      .dispatch("old-service/fetchGetLargestEconomicGroupsYearDate")
      .then((response: IResponseGetLargestEconomicGroupsYear) => {
        this.largestEconomicGroupsYearDate.data = response.data.Grupos.map(
          (group: IGroup, index: number) => ({
            ...group,
            columnSettings: {
              fill: index % 2 ? this.reportColor : "#828282",
            },
          })
        );
        this.largestEconomicGroupsYearDate.title = response.data.Titulo;
        this.loadingLargestEconomicGroupsYearDate = false;
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title:
              "Não foi possível consultar maiores grupos econômicos no ano!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  }

  fetchGetGrowthByBrand() {
    this.loadingGrowthByBrand = true;

    this.$store
      .dispatch("old-service/fetchGetGrowthByBrand")
      .then((response: IResponseGetGrowthByBrand) => {
        this.growthByBrand.data = response.data.Variacoes.map(
          (variant: IBrandVariation, index: number) => ({
            ...variant,
            columnSettings: {
              fill:
                variant.Nome == this.getBrand ? this.reportColor : "#828282",
            },
          })
        );

        this.growthByBrand.title = response.data.Titulo;
        this.loadingGrowthByBrand = false;
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Não foi possível consultar o crescimento das marcas!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  }

  fetchDirectSaleVsRetailSaleBrand() {
    this.loadingDirectSaleVsRetailSaleBrand = true;

    this.$store
      .dispatch("old-service/fetchDirectSaleVsRetailSaleBrand")
      .then((response: IResponseGetDirectSaleVsRetailSaleBrand) => {
        this.directSaleVsRetailSaleBrandDate.data = [
          {
            category: "Venda Direta",
            value: response.data.VendaDireta,
            columnSettings: {
              fill: "#095256",
            },
          },
          {
            category: "Varejo",
            value: response.data.VendaVarejo,
            columnSettings: {
              fill: this.reportColor,
            },
          },
        ];

        this.directSaleVsRetailSaleBrandDate.title = response.data.Titulo;
        this.loadingDirectSaleVsRetailSaleBrand = false;
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title:
              "Não foi possível consultar os dados de venda direta x venda varejo da marca!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  }

  fetchDirectSaleVsRetailSaleYear() {
    this.loadingDirectSaleVsRetailSaleYear = true;

    this.$store
      .dispatch("old-service/fetchDirectSaleVsRetailSaleYear")
      .then((response: IResponseGetDirectSaleVsRetailSaleYear) => {
        this.directSaleVsRetailSaleYearDate.data = [
          {
            category: "Venda Direta",
            value: response.data.VendaDireta,
            columnSettings: {
              fill: "#095256",
            },
          },
          {
            category: "Varejo",
            value: response.data.VendaVarejo,
            columnSettings: {
              fill: this.reportColor,
            },
          },
        ];

        this.directSaleVsRetailSaleYearDate.title = response.data.Titulo;
        this.loadingDirectSaleVsRetailSaleYear = false;
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title:
              "Não foi possível consultar os dados de venda direta x venda varejo do mercado!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  }

  fetchGetBrandEvolution() {
    this.loadingBrandEvolution = true;

    this.$store
      .dispatch("old-service/fetchGetBrandEvolution")
      .then((response: IResponseGetBrandEvolution) => {
        this.brandEvolutionData.title = response.data.Titulo;
        this.brandEvolutionData.date = response.data.Data;

        this.brandEvolutionData.data = response.data.EvolucaoMes.map(
          (evolution: IEvolution) => ({
            date: evolution.Data,
            Total: evolution.VendaTotal,
            Varejo: evolution.VendaVarejo,
          })
        );

        this.loadingBrandEvolution = false;
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title:
              "Não foi possível consultar os dados da evolução da marca mensal!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  }

  fetchGetDailyBrandEvolution() {
    this.loadingDailyBrandEvolution = true;

    this.$store
      .dispatch("old-service/fetchGetDailyBrandEvolution")
      .then((response: IResponseGetDailyBrandEvolution) => {
        this.dailyBrandEvolutionData.title = response.data.Titulo;
        this.dailyBrandEvolutionData.date = response.data.Data;

        this.dailyBrandEvolutionData.data = response.data.EvolucaoMes.map(
          (evolution: IEvolution) => ({
            date: evolution.Data,
            Total: evolution.VendaTotal,
            Varejo: evolution.VendaVarejo,
          })
        );

        this.loadingDailyBrandEvolution = false;
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title:
              "Não foi possível consultar os dados da evolução da marca diária!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  }

  fetchUpdateDashboard() {
    this.updateDashboard = true;
    this.$store
      .dispatch("old-service/fetchUpdateDashboard")
      .then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Pendência de atualizar dashboard criada!",
            icon: "CheckIcon",
            variant: "success",
          },
        });
        this.refreshData();
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Ocorreu um erro ao solicitar atualização do dashboard!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.updateDashboard = false;
      });
  }

  handleChartTotalMarketShareCurrentMonth(root: am5.Root) {
    this.totalMarketShareCurrentMonth.root = root;
  }

  handleTotalMarketShareYearToDate(root: am5.Root) {
    this.totalMarketShareYearToDate.root = root;
  }

  handlePie1(root: am5.Root) {
    if (localStorage.getItem("domain") == "Abracaf") {
      this.directSaleVsRetailSaleYearDate.root = root;
    } else {
      this.directSaleVsRetailSaleBrandDate.root = root;
    }
  }

  handlePie2(root: am5.Root) {
    if (localStorage.getItem("domain") == "Abracaf") {
      this.directSaleVsRetailSaleBrandDate.root = root;
    } else {
      this.directSaleVsRetailSaleYearDate.root = root;
    }
  }

  handleBrandEvolutionData(root: am5.Root) {
    this.brandEvolutionData.root = root;
  }

  handleDailyBrandEvolutionData(root: am5.Root) {
    this.dailyBrandEvolutionData.root = root;
  }

  handleLargestEconomicGroupsYearDate(root: am5.Root) {
    this.largestEconomicGroupsYearDate.root = root;
  }

  handleGrowthByBrand(root: am5.Root) {
    this.growthByBrand.root = root;
  }
}
