
import {
  BCard,
  BSkeletonImg,
  BSkeleton,
  BSkeletonWrapper,
} from "bootstrap-vue";
import AvIcon from "@/components/av-icon/AvIcon.vue";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import am5locales_pt_BR from "@amcharts/amcharts5/locales/pt_PT";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { IBarConfig } from "@/components/av-charts/interfaces/IConfig";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  name: "AvChartBar",
  components: {
    BCard,
    BSkeletonImg,
    BSkeleton,
    BSkeletonWrapper,
    AvIcon,
  },
})
export default class AvChartBar extends Vue {
  @Prop({ required: true }) data!: Array<any>;
  @Prop({ required: true }) id!: string;
  @Prop({ required: true }) config!: IBarConfig;

  am5 = am5;
  am5xy = am5xy;
  series: any = null;
  root: am5.Root | null = null;

  // Watchs
  @Watch("data", { immediate: true })
  refreashChart() {
    this.root?.dispose();
    if (document.getElementById(this.id)) this.am5.ready(this.am5Read);
  }

  mounted() {
    this.refreashChart();
  }

  // Functions
  am5Read() {
    this.series = null;
    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    this.root = this.setUpRoot();

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    let chart = this.setUpChart(this.root);

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let axis = this.setUpAxis(this.root, chart);

    const color =
      this.$store.state.appConfig.layout.skin === "dark"
        ? "#d0d2d6"
        : "#1e1e1e";
    this.setUpSeries(this.root, chart, axis, color);

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    this.series.appear();
    chart.appear(1000, 100);

    this.$emit("root", this.root);
  }

  setUpRoot() {
    let root: am5.Root = this.am5.Root.new(this.id);

    root.locale = am5locales_pt_BR;

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    let responsive = am5themes_Responsive.newEmpty(root);

    const applying = () => {
      this.series.bullets.setAll([]);

      this.series.data.setAll(this.data);
    };

    const removing = () => {
      this.series.bullets.push(function (root: am5.Root) {
        return am5.Bullet.new(root, {
          locationX: 0.5,
          locationY: 0.5,
          sprite: am5.Label.new(root, {
            text: "{valueY}",
            centerX: am5.percent(50),
            centerY: am5.percent(50),
            fill: am5.color(0xffffff),
            populateText: true,
          }),
        });
      });

      this.series.data.setAll(this.data);
    };

    responsive.addRule({
      name: "AxisRendererY",
      relevant: function (width) {
        return width < 650;
      },
      applying: applying,
      removing: removing,
    });

    root.setThemes([am5themes_Animated.new(root), responsive]);

    return root;
  }

  setUpChart(root: am5.Root) {
    return root.container.children.push(
      this.am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
        layout: root.verticalLayout,
      })
    );
  }

  setUpAxis(root: am5.Root, chart: am5xy.XYChart) {
    let xAxis = chart.xAxes.push(
      this.am5xy.CategoryAxis.new(root, {
        categoryField: this.config.categoryField,
        renderer: this.am5xy.AxisRendererX.new(root, {
          minGridDistance: this.config.renderer?.minGridDistance || 55,
        }),
      })
    );

    xAxis.get("renderer").labels.template.setAll({
      ...this.config.renderer?.labels,
      paddingTop: this.config.renderer?.labels?.paddingTop || 20,
      paddingLeft: this.config.renderer?.labels?.paddingLeft || 0,
      rotation: this.config.renderer?.labels?.rotation || undefined,
    });

    xAxis.data.setAll(this.data);

    let yAxis = chart.yAxes.push(
      this.am5xy.ValueAxis.new(root, {
        renderer: this.am5xy.AxisRendererY.new(root, {}),
      })
    );

    return {
      xAxis: xAxis,
      yAxis: yAxis,
    };
  }

  setUpSeries(
    root: am5.Root,
    chart: am5xy.XYChart,
    axis: { xAxis: any; yAxis: any },
    color: string
  ) {
    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    this.series = chart.series.push(
      this.am5xy.ColumnSeries.new(root, {
        xAxis: axis.xAxis,
        yAxis: axis.yAxis,
        valueYField: this.config.valueYField,
        categoryXField: this.config.categoryXField,
      })
    );

    this.series.columns.template.setAll({
      tooltipText: this.config.tooltipText || "{valueY}",
      tooltipY: 0,
      strokeOpacity: 0,
      cornerRadiusTL: 5,
      cornerRadiusTR: 5,
      templateField: "columnSettings",
    });

    this.series.bullets.push(function (root: am5.Root) {
      return am5.Bullet.new(root, {
        locationX: 0.5,
        locationY: 0.5,
        sprite: am5.Label.new(root, {
          text: "{valueY}",
          centerX: am5.percent(50),
          centerY: am5.percent(50),
          populateText: true,
          fill: am5.color(color),
        }),
      });
    });

    this.series.data.setAll(this.data);
  }
}
